import React from "react";
import TeamDiversityContainer from "../containers/teamDiversityContainer";
import TeamAssessmentContainer from "../containers/teamAssessmentContainer";
import TeamsScapeReportContainer from "../containers/teamScapeReportContainer";
import { Button } from "antd";
import TeamMembersContainer from "../containers/teamMembersContainer";
import IssueNewBadgeContainer from "modules/kudos-user/container/issueNewBadgeContainer";
import GiveFeedbackContainer from "modules/home/containers/giveFeedbackContainer";
import one_on_one_route from "modules/one-on-one/route";
import { dasboardIcons } from "modules/home/dashboard-icons";
import { MetaTags } from "modules/look";

const TeamsDashboardView = (props) => {
  const { userPermission, permission, navigateRoute, one_on_one_status, meeting_effectiveness, show_last_feedback_status } = props;
  const [give_kudos, setGiveKudos] = React.useState(false);
  const stars = [1, 2, 3, 4, 5] 
  return (
    <> <MetaTags title="My Team" description="My team dashboard" />
      <div
        className="root-user-container"
        style={{ display: "flex", flexDirection: "column", gap: "1em" }}
      >
        <div className="user-dashboard-container">
          <div className="user-dasboard-left-container">
            <div>
              <TeamDiversityContainer {...props} />
            </div>
            <div>
              <TeamAssessmentContainer {...props} />
            </div>
          </div>
          <div className="user-dasboard-right-container">
            {permission?.oneonone_permission && <div className="user-dasboard-small-card-container">
              <h4 className="user-dashboard-small-card-title">
                Your 1:1 Effectiveness score
              </h4>
              {/* <p className="user-dashboard-small-card-para">here you will see the your effectiveness score</p> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: ".75em",
                }}
              >
                <h4 className="user-dashboard-small-card-para" style={{ fontWeight: "500" }}>Host Skills</h4>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1em" }}
                >
                  {
                    stars?.map((star, index) =>
                      <img src={((meeting_effectiveness?.hot_skills / 20) >= (index + 1)) ? dasboardIcons?.start_active : dasboardIcons?.start_inactive} alt="star" />
                    )
                  }
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: ".75em",
                  marginTop: '1em'
                }}
              >
                <h4 className="user-dashboard-small-card-para" style={{ fontWeight: "500" }}>Meeting Experience</h4>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1em" }}
                >
                  {
                    stars?.map((star, index) =>
                      <img src={((meeting_effectiveness?.meeting_effectiveness / 20) >= (index + 1)) ? dasboardIcons?.start_active : dasboardIcons?.start_inactive} alt="star" />
                    )
                  }
                </div>
              </div>
            </div>}
            {permission?.oneonone_permission && <div className="user-dasboard-small-card-container">
              <h4 className="user-dashboard-small-card-title">Schedule 1:1</h4>
              {one_on_one_status?.difference > 0 ? <p className="user-dashboard-small-card-para">Your last schedule 1:1 is {one_on_one_status?.difference} {one_on_one_status?.difference !== 1 ? 'day’s' : 'day'} ago</p> :
                <p className="user-dashboard-small-card-para">Your last scheduled 1:1 is Today</p>}
              <Button
                className="user-board-welcome-banner-button"
                style={{ height: "2.5em", fontSize: "1.1em" }}
                onClick={() => navigateRoute(one_on_one_route.addOneonOne)}
              >
                Schedule now
              </Button>
            </div>}
            {(permission?.feedback_permission || permission?.kudos_permission) && <div className="user-dasboard-small-card-container">
              <h4 className="user-dashboard-small-card-title">Feedback</h4>
              {show_last_feedback_status && <p className="user-dashboard-small-card-para">
                You haven’t given feedback to any one in last few days
              </p>}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1em",
                  flexWrap: "wrap",
                }}
              >
                {permission?.feedback_permission && (
                  <GiveFeedbackContainer
                    feedbackPermission={{requried_permission:permission?.feedback_permission,okr_permission:permission?.okr_permission,kudos_permission:permission?.kudos_permission}}
                    {...props}
                  />
                )}
                {permission?.award_badge_permission && <Button
                  className="user-board-welcome-banner-button"
                  style={{
                    height: "2.5em",
                    fontSize: "1.1em",
                    marginTop: ".75em",
                  }}
                  onClick={() => setGiveKudos(true)}
                >
                  GIVE KUDOS
                </Button>}
              </div>
            </div>}
            <div>
              <TeamMembersContainer {...props} />
            </div>
          </div>
        </div>
        {/* {
          team_employees?.length ? */}
        <div>
          <TeamsScapeReportContainer {...props} />
        </div>
        {/* : '' */}
        {/* } */}

        {give_kudos && (
          <IssueNewBadgeContainer
            userPermission={userPermission}
            show_popup={give_kudos}
            onCancel={() => setGiveKudos(false)}
            {...props}
          />
        )}
      </div>
    </>
  );
};

export default TeamsDashboardView;
