import {Button, Input, message, Select, Spin } from "antd";
import React from "react";
import YourKudosContainer from "../container/yourKuodsContainer";
import '../css/kudos-card.css'
import EngagementBoardContainer from "../container/engaementBoardContainer";
import styled from "styled-components";
import GiveKudosNewPopupContainer from "../container/giveKudosNewPopupContainer";
import search_icon from '../../assets/search-icon.svg';
import { Capitalize, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import moment from "moment";
import { kudosBadgeColors} from "../container/configs/kudosColorConfig";
import KudosCustomPickerView from "./kudosCustomPickerView";
import { kudosGif, KudosIcons } from "../icons/kudosIcons";
const KudosHomePageView = (props) => {
    const { me, new_awarder_badge_list, award_badge_loading, getAwardBadgesList, engagement_board_data, engagement_loading, getUserBadges, issuer_page_info,
        badge_page_info, receipient_page_info, onPagination, badge_comment_loading, badge_comment_list, getBadgeComments, CreateBadgeComment, createBadgeReaction, createBadgeCommentReaction, permission,updateBadgeReaction } = props
    const [comment_id, setCommentId] = React.useState()
    const [replay_comment_id, setReplayCommentId] = React.useState()
    const [filter, setFilter] = React.useState()
    const [give_kudos, setGiveKudos] = React.useState(false)
    const [open_date_picker, setOpenDatePicker] = React.useState(false)
    const [show_reactions, setShowReactions] = React.useState();
    const [inputValue, setInputValue] = React.useState("");
    const [replay_input_value, setReplayInputValue] = React.useState("")
    const [data_source, setDataSource] = React.useState([])
    const [filter_list, setFilterList] = React.useState([
        {
            key: "my_kudos",
            value: "my_kudos",
            label: "My Kudos",
        },
        {
            key: "organization",
            value: "organization",
            label: "My Organization",
        },
        {
            key: "quarter",
            value: "quarter",
            label: "Date",
        }
    ]);

    React.useEffect(() => {
        if (new_awarder_badge_list) {
            let sort_list = new_awarder_badge_list?.sort((a, b) =>
                new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
            );
            setDataSource(sort_list)
        }
    }, [new_awarder_badge_list])

    // const addEmoji = (emoji) => {
    //     setInputValue((prev) => prev + emoji);
    // };

    // const addEmojiReplay = (emoji) => {
    //     setReplayInputValue((prev) => prev + emoji);
    // }

    const handleSelect = (selectedValue) => {
        setFilter(selectedValue);

        if (selectedValue === "quarter") {
            setOpenDatePicker(true);
        } else {
            setOpenDatePicker(false);
        }
    };

    React.useEffect(() => {
        if (me?.employee?.orgPocEmployee?.totalCount > 0 || me?.employee?.orgCeoEmployee?.totalCount > 0) {
            setFilter("organization")
        }
        else if (me?.employee?.verticalHeadEmployee?.edges?.length > 0) {
            setFilter("Vertical")
        }
        else if (me?.employee?.teamManagerEmployee?.edges?.length > 0) {
            setFilter("Team")
        }
        else if (me?.employee?.orgPocEmployee?.totalCount === 0 &&
            me?.employee?.orgCeoEmployee?.totalCount === 0 &&
            me?.employee?.verticalHeadEmployee?.edges?.length === 0 &&
            me?.employee?.teamManagerEmployee?.edges?.length === 0) {
            setFilter("my_kudos")
        }
    }, [me])

    React.useEffect(() => {
        const additionalFilters = [];
        if (me?.employee?.teamManagerEmployee?.edges?.length > 0 || me?.employee?.teamMembersEmployee?.edges?.length > 0) {
            additionalFilters.push({
                key: "team_kudos",
                value: "Team",
                label: "My Team",
            });
        }
        if (me?.employee?.verticalHeadEmployee?.edges?.length > 0 || me?.employee?.verticalMembersEmployee?.edges?.length > 0) {
            additionalFilters.push({
                key: "vertical_kudos",
                value: "Vertical",
                label: "My Vertical",
            });
        }

        if (additionalFilters.length > 0) {
            setFilterList((prevFilterList) => {
                const updatedList = [...prevFilterList];
                updatedList.splice(1, 0, ...additionalFilters);
                return updatedList;
            });
        }
    }, [me]);

    const removeDuplicates = (data) => {
        var setObj = new Set();
        var unique_data_set = data?.reduce((acc, item) => {
            if (!setObj.has(item)) {
                setObj.add(item)
                acc.push(item)
            }
            return acc;
        }, [])
        return unique_data_set
    }

    React.useEffect(() => {
        if (filter && filter === "my_kudos") {
            getAwardBadgesList({ recipient: me?.employee?.id })
        }
        else if (filter === "Team") {
            let team_list = removeDuplicates(me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)).concat(me?.employee?.teamMembersEmployee?.edges?.map(({ node }) => getIntFromString(node?.id))))
            getAwardBadgesList({ recipient_TeamMembersEmployee_In: team_list })
            getUserBadges({ recipient_TeamMembersEmployee_In: team_list })
        }
        else if (filter === "Vertical") {
            let vertical_list = removeDuplicates(me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)).concat(me?.employee?.verticalMembersEmployee?.edges?.map(({ node }) => getIntFromString(node?.id))))
            getAwardBadgesList({ recipient_VerticalMembersEmployee_In: vertical_list })
            getUserBadges({ recipient_VerticalMembersEmployee_In: vertical_list })
        }
        else if (filter === "organization") {
            getAwardBadgesList()
            getUserBadges()
        }
        else if (filter && typeof filter === "object") {
            getAwardBadgesList({ createdAt_Range: [filter?.startDate, filter?.endDate] })
            getUserBadges({ createdAt_Range: [filter?.startDate, filter?.endDate] })
        }
        // else if(filter == 'quarter'){
        //     setOpenDatePicker(true)
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const onFilterCustomDate = (date) => {
        setOpenDatePicker(false)
        getAwardBadgesList({ createdAt_Range: [date[0], date[1]] })
        getUserBadges({ createdAt_Range: [date[0], date[1]] })
    }

    const onCloseDate = (key) => {
        setTimeout(() => {
            setOpenDatePicker(key)
        }, 350)

        if (me?.employee?.teamManagerEmployee?.edges?.length > 0 || me?.employee?.teamMembersEmployee?.edges?.length > 0) {
            setFilter("Team")
        }
        if (me?.employee?.verticalHeadEmployee?.edges?.length > 0 || me?.employee?.verticalMembersEmployee?.edges?.length > 0) {
            setFilter("Vertical")
        }
        if (me?.employee?.orgPocEmployee?.totalCount > 0 || me?.employee?.orgCeoEmployee?.totalCount > 0) {
            setFilter("organization")
        }
        if (me?.employee?.orgPocEmployee?.totalCount === 0 &&
            me?.employee?.orgCeoEmployee?.totalCount === 0 &&
            me?.employee?.verticalHeadEmployee?.edges?.length === 0 &&
            me?.employee?.verticalMembersEmployee?.edges?.length === 0 &&
            me?.employee?.teamManagerEmployee?.edges?.length === 0 &&
            me?.employee?.teamMembersEmployee?.edges?.length === 0
        ) {
            setFilter("my_kudos")
        }

    }

    const handleShare = (data) => {
        const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(data?.imageUrl)}&text=${encodeURIComponent(data?.textContent)}`;
        window.open(linkedInUrl, '_blank', 'noopener,noreferrer');
    };

    const SendComment = (badge_id, parent_id) => {
        if ((!inputValue || inputValue.trim() === "") && (!replay_input_value || replay_input_value.trim() === "")) {
            message.error("Please Enter Any Comment")
            return;
        }
        let badgeAwardCommentData = {
            badgeId: badge_id,
            employeeId: me?.employee?.id,
            content: parent_id ? replay_input_value : inputValue,
            ...(parent_id ? { parentId: parent_id } : {}),
        }
        CreateBadgeComment(badgeAwardCommentData)
        setTimeout(()=>{
            setInputValue("")
            setReplayInputValue("")
        },400)
    }

    const SendReaction = (badge, reaction) => {
        let reaction_list =badge?.reactions?.edges?.filter(({node})=>node?.employee?.id === me?.employee?.id && node?.reaction?.toLowerCase() === reaction?.toLowerCase()   )
        if(reaction_list.length > 0){
            message.destroy()
            message.warning("This reaction has already been added to this Kudos!")
            return
        }
        let user_reaction  = badge?.reactions?.edges?.filter(({node})=>node?.employee?.id === me?.employee?.id)
        if(user_reaction?.length > 0){
            updateBadgeReaction({id:user_reaction[0]?.node?.id, reaction:reaction,badge_id:badge?.id})
        }
        else{
            createBadgeReaction({ id: badge?.id, employeeId: me?.employee?.id, type: reaction })
        }      
    }

    const commnetReaction = (comment_id, reaction, reactions) => {
        const userId = me?.employee?.id;
        let reaction_status = reactions?.some(({ node }) => node?.employee?.id === userId) || false;
        if (reaction_status) {
            message.destroy()
            message.warning("You have already reacted to this comment")
            return
        }
        createBadgeCommentReaction({ id: comment_id, employeeId: me?.employee?.id, type: reaction })
        
    }

    const checkUserLiked = (reactions) => {
        const userId = me?.employee?.id;
        return reactions?.some(({ node }) => node?.employee?.id === userId) || false;
    }

    const getReactionCount = (reactions, type) => {
        if (reactions?.length) {
            let count = reactions?.filter(({ node }) => node?.reaction?.toLowerCase() === type?.toLowerCase())?.length
            return count
        }
        else return 0
    }

    const getRelativeTime = (date) => {
        const diffInMinutes = moment().diff(moment(date), 'minutes');

        if (diffInMinutes < 60) {
            return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        }
        else {
            return moment(date).fromNow();
        }

    };

    return (
        <div className="kudos-card-root">
            <div className="kudos-left-board-container-responseive" style={{ width: "100%"}}>
                <div className="kudos-left-board-cards-responseive">
                    <div style={{width:'100%'}}>
                    <YourKudosContainer {...props} />
                    </div>
                    <div style={{width:'100%'}}>
                    <EngagementBoardContainer {...props} engagement_board_data={engagement_board_data} engagement_loading={engagement_loading} />
                    </div>
                </div>
                
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: 'start', width: "100%", gap: "1em" }}>
                <div className="kudos-card-section-list-container" style={{ width: "60%", display: "flex", flexDirection: 'column', gap: "2em" }}>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: '1em', flexWrap: "wrap", width: "100%" }}>
                        {permission && permission?.can_award_badge && <Button className="give-kudos-btn" style={{ width: "fit-content", cursor: "pointer" }} onClick={() => { setGiveKudos(true) }}>Give Kudos</Button>}
                        <div style={{ display: 'flex', gap: "1em", alignItems: 'center' }}>
                            <div style={{ position: "relative", width: "100%", maxWidth: "12.5em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".25em" }}>
                                <img src={search_icon} alt="search_icon" style={{ height: "20px" }} />
                                <Input style={{ width: "100%", border: "none", height: "3em", fontSize: "1em", fontFamily: "Poppins", backgroundColor: "transparent" }} placeholder="Search" onChange={(e) => getAwardBadgesList({ badge_Title_Icontains: e?.target?.value, issuer_User_FirstName_Icontains: e?.target?.value, recipient_User_FirstName_Icontains: e?.target?.value })} />
                            </div>
                            <CustomSelect
                                dropdownClassName="content-dropdown"
                                placeholder="Sort By"
                                value={filter}
                                onSelect={handleSelect}
                            >
                                {
                                    filter_list.map((item) => (
                                        <Select.Option
                                            className="kudos-card-filter-text"
                                            key={item.key}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Select.Option>
                                    ))}
                            </CustomSelect>
                        </div>
                    </div>
                    <Spin spinning={award_badge_loading}>
                        <div className="kudos-card-container" style={{ minHeight: "40vh" }}>
                            {data_source?.length > 0 && data_source?.map(item => (
                                <div className="kudos-new-card" onMouseLeave={() => { setCommentId(); setShowReactions() }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: '1em' }}>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".5em" }}>

                                            {item?.recipient?.profile?.profileImage ? <img style={{ height: '3em', width: "3em", borderRadius: "50%" }} src={item?.recipient?.profile?.profileImage} alt="" /> :
                                                <div style={{ height: '3em', width: "3em", borderRadius: "50%", background: "#AABAC6", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                    <h4 style={{ fontSize: "2em", textAlign: "center", fontWeight: "600", fontFamily: "Poppins", color: "#fff", margin: 0 }}>{item?.recipient?.firstName?.charAt(0)}</h4>
                                                </div>}
                                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                                <h2 className="kudos-card-user-name">{item?.recipient?.firstName}</h2>
                                                {item?.recipient?.role && <h4 className="kudos-card-user-role">{item?.recipient?.role}</h4>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Kudos detail Section */}
                                    <div className="kudos-card-detail-container" style={{ background: kudosBadgeColors[item?.badge?.badgeFamily?.title?.replace(/\s+/g, '').toLowerCase()] ? kudosBadgeColors[item?.badge?.badgeFamily?.title?.replace(/\s+/g, '').toLowerCase()]?.backgroundColor : 'rgba(46, 85, 107, 1)' }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "start", gap: '3em' }}>
                                            <div>
                                                <p className="kudos-card-detail-description kudos-card-text-overflow"><span style={{ fontWeight: "600" }}>Rational- </span>{item?.rational}</p>
                                            </div>
                                            <div className="Kudos-card-badge-image-container">
                                                <div className="Kudos-card-badge-image-container-inner">
                                                    <div className="Kudos-card-badge-image-container-inner-front" style={{ cursor: "default" }}>
                                                        <img className="kudos-card-badge-image" src={item?.badge?.image} alt="" />
                                                    </div>
                                                    <div className="Kudos-card-badge-image-container-inner-back" style={{ cursor: "default" }}>
                                                        <p className="kudos-card-detail-description" style={{ textAlign: "left" }}>{item?.badge?.behaviouralDescription}</p>
                                                    </div>
                                                </div>
                                                <h2 className="kudos-card-detail-title" style={{ textAlign: "center" }}>{item?.badge?.title}</h2>
                                            </div>
                                        </div>
                                        <div className="kudos-card-award-btn" style={{ background: kudosBadgeColors[item?.badge?.badgeFamily?.title?.replace(/\s+/g, '').toLowerCase()] ? kudosBadgeColors[item?.badge?.badgeFamily?.title?.replace(/\s+/g, '').toLowerCase()]?.buttonColor : 'rgba(46, 85, 107, 1)', marginTop: "-50px" }}>Awarded By: {item?.issuer?.firstName}</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "end", alignItems: "flex-end", paddingRight: "3.25em" }}>
                                        <h4 className="kudos-card-user-name">{displayUtcToUserTimezone(item?.createdAt, 'DD MMM YYYY')}</h4>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: '1em', padding: "1em 3em" }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', position: "relative", gap: ".25em" }}>

                                            {show_reactions === item?.id && <div className="kudos-reaction-card" style={{ position: "absolute", bottom: "2.75em", marginLeft: "-.5em" }}>
                                                <div className="kudos-react-icon" style={{ position: "relative" }}>
                                                    <img className="kudos-react-icon-img" src={KudosIcons?.party} alt="party" />
                                                    <img className="kudos-react-gif" onClick={() => SendReaction(item, 'party')} src={kudosGif?.party_giff} alt="party_giff" />
                                                    <p className="kudos-card-user-name" style={{ margin: "0px", fontSize: ".85em", position: "absolute", top: "-10px", right: "-5px" }}>{getReactionCount(item?.reactions?.edges, 'party')}</p>
                                                </div>
                                                <div className="kudos-react-icon" style={{ position: "relative" }}>
                                                    <img className="kudos-react-icon-img" src={KudosIcons?.like} alt="like" />
                                                    <img className="kudos-react-gif" onClick={() => SendReaction(item, 'like')} src={kudosGif?.like_giff} alt="like_giff" />
                                                    <p className="kudos-card-user-name" style={{ margin: "0px", fontSize: ".85em", position: "absolute", top: "-10px", right: "-5px" }}>{getReactionCount(item?.reactions?.edges, 'like')}</p>
                                                </div>
                                                <div className="kudos-react-icon" style={{ position: "relative" }}>
                                                    <img className="kudos-react-icon-img" src={KudosIcons?.heart} alt="heart" />
                                                    <img className="kudos-react-gif" onClick={() => SendReaction(item, 'love')} src={kudosGif?.heart_giff} alt="heart_giff" />
                                                    <p className="kudos-card-user-name" style={{ margin: "0px", fontSize: ".85em", position: "absolute", top: "-10px", right: "-5px" }}>{getReactionCount(item?.reactions?.edges, 'love')}</p>
                                                </div>


                                            </div>}
                                            {permission && permission?.badgeawardreaction && <h2 className={`kudos-card-user-name ${show_reactions === item?.id ? 'kudos-card-action-btn-active' : 'kudos-card-action-btn'}`}
                                                onClick={() => { show_reactions === item?.id ? setShowReactions() : setShowReactions(item?.id) }}>Reaction</h2>}
                                        </div>
                                        {permission && permission?.badgeawardcomment && <h2 className={`kudos-card-user-name ${comment_id === item?.id ? 'kudos-card-action-btn-active' : 'kudos-card-action-btn'}`}
                                            onClick={() => { setCommentId(item?.id); getBadgeComments({ id: item.id }) }}
                                        >Comment</h2>}
                                        <h2 className="kudos-card-user-name kudos-card-action-btn" onClick={() => handleShare({ imageUrl: item?.badge?.image, textContent: item?.rational })}>Share</h2>
                                    </div>

                                    {/********************* Comment list section ****************************/}

                                    {comment_id === item?.id && <div className="kudos-card-comment-section">
                                        {/* <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
                                        <img src={KudosIcons?.like_active} alt="like_active" />
                                        <p className="kudos-card-user-name" style={{ margin: "0px", fontSize: ".75em" }}>20</p>
                                    </div> */}
                                        <div style={{ position: "relative", width: "100%", marginBottom: "1em" }}>
                                            <Input
                                                className="kudos-comment-input"
                                                placeholder="Write a comment"
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)}

                                            />
                                            {/* <img src={KudosIcons?.kudos_emoji_icon} alt="kudos_emoji_icon"
                                            style={{ position: "absolute", right: "50px", top: "10px", cursor: "pointer" }}
                                            tabIndex="0"
                                            onClick={() => setShowPicker((prev) => !prev)}
                                        /> */}
                                            <img src={KudosIcons?.kudos_comment_sent} alt="kudos_comment_sent"
                                                style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }}
                                                onClick={() => SendComment(item?.id)} />
                                            {/* Emoji Picker */}
                                            {/* {showPicker && (
                                            <div
                                                className="kudos-emoji-picker-container"
                                            >
                                                {smileysEmojis?.map((emoji, index) => (
                                                    <span
                                                        key={index}
                                                        onClick={() => addEmoji(emoji)}
                                                        style={{
                                                            cursor: "pointer",
                                                            fontSize: "20px",
                                                            transition: "transform 0.2s",
                                                        }}
                                                        onMouseEnter={(e) => (e.target.style.transform = "scale(1.3)")}
                                                        onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                                                    >
                                                        {emoji}
                                                    </span>
                                                ))}
                                            </div>
                                        )} */}
                                        </div>
                                        <Spin spinning={badge_comment_loading}>
                                            <div className="kudos-badge-comment-section-scroll" style={{ display: "flex", flexDirection: "column", gap: '1em', width: '100%', marginTop: "1em" }}>
                                                {!badge_comment_loading && badge_comment_list?.map(comment => (
                                                    <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1em" }}>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: "1em", width: '100%', justifyContent: 'start', alignItems: "start" }}>
                                                            <div style={{ width: "3em" }}>
                                                                {comment?.user?.user_detail?.profile?.profileImage ? <img style={{ height: '3em', width: "3em", borderRadius: "50%" }} src={comment?.user?.user_detail?.profile?.profileImage} alt="" /> :
                                                                    <div style={{ height: '3em', width: "3em", borderRadius: "50%", background: "#AABAC6", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                                        <h4 style={{ fontSize: "1.5em", textAlign: "center", fontWeight: "600", fontFamily: "Poppins", color: "#fff", margin: 0 }}>{Capitalize(comment?.user?.user_detail?.firstName.charAt(0))}</h4>
                                                                    </div>}
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
                                                                <div style={{ display: "flex", flexDirection: "row", gap: ".5em", justifyContent: "start", alignItems: 'center' }}>
                                                                    <h4 className="kudos-card-user-name" style={{ margin: "0px" }}>{Capitalize(comment?.user?.user_detail?.firstName)} {Capitalize(comment?.user?.user_detail?.lastName)}</h4>
                                                                    <p className="kudos-card-user-name" style={{ color: 'rgba(108, 117, 125, 1)', fontSize: ".85em", fontWeight: "400" }}>{getRelativeTime(comment?.createdAt)}</p>
                                                                </div>
                                                                <h5 className="kudos-card-user-name" style={{ fontWeight: "400", fontSize: ".85em", margin: "0px" }}>{comment?.content}</h5>
                                                                <div style={{ display: "flex", flexDirection: "row", gap: ".5em", justifyContent: 'start', alignItems: "center" }}>
                                                                    {permission && permission?.badgeawardcommentreaction && <div style={{ display: "flex", flexDirection: "row", gap: ".5em", justifyContent: 'start', alignItems: "center" }}>
                                                                        {checkUserLiked(comment?.reaction?.edges) ? <img src={KudosIcons?.like_active} alt="like_active" style={{ cursor: "pointer" }} onClick={() => commnetReaction(comment?.id, 'like', comment?.reaction?.edges)} /> : <img src={KudosIcons?.kudos_heart_inactive} style={{ cursor: "pointer" }} alt="like_active" onClick={() => commnetReaction(comment?.id, 'like', comment?.reaction?.edges)} />}
                                                                        {comment?.reaction?.totalCount > 0 && <p className="kudos-card-user-name" style={{ margin: "0px", fontSize: ".75em" }}>{comment?.reaction?.totalCount}</p>}
                                                                    </div>}
                                                                    {replay_comment_id !== comment?.id && permission && permission?.badgeawardcomment && comment?.user?.emp_id !== me?.employee?.id && <p className="kudos-card-user-name" style={{ color: 'rgba(108, 117, 125, 1)', fontSize: ".85em", fontWeight: "400", cursor: "pointer" }} onClick={() => { setReplayCommentId(comment?.id); setInputValue() }}>Reply</p>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/**************** Replay Section *******************/}

                                                        <div style={{ display: 'flex', flexDirection: "column", paddingLeft: "4em", paddingRight: "1em" }}>
                                                            {comment?.user?.emp_id !== me?.employee?.id && replay_comment_id === comment?.id && <div style={{ position: "relative", width: "100%" }}>
                                                                <Input
                                                                    className="kudos-comment-input"
                                                                    placeholder="Write a replay"
                                                                    value={inputValue}
                                                                    onChange={(e) => setReplayInputValue(e.target.value)}

                                                                />
                                                                {/* <img src={KudosIcons?.kudos_emoji_icon} alt="kudos_emoji_icon"
                                                                style={{ position: "absolute", right: "50px", top: "10px", cursor: "pointer" }}
                                                                tabIndex="0"
                                                                onClick={() => setShowPicker((prev) => !prev)}
                                                            /> */}
                                                                <img src={KudosIcons?.kudos_comment_sent} alt="kudos_comment_sent"
                                                                    style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }}
                                                                    onClick={() => SendComment(item?.id, comment?.id)} />
                                                                {/* Emoji Picker */}
                                                                {/* {showPicker && (
                                                                <div
                                                                    className="kudos-emoji-picker-container"
                                                                >
                                                                    {smileysEmojis?.map((emoji, index) => (
                                                                        <span
                                                                            key={index}
                                                                            onClick={() => addEmojiReplay(emoji)}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                fontSize: "20px",
                                                                                transition: "transform 0.2s",
                                                                            }}
                                                                            onMouseEnter={(e) => (e.target.style.transform = "scale(1.3)")}
                                                                            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                                                                        >
                                                                            {emoji}
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            )} */}
                                                            </div>}
                                                            <div style={{ display: "flex", flexDirection: "column", gap: '1em', width: '100%', marginTop: "1em" }}>
                                                                {!badge_comment_loading && comment?.replies?.map(reply => (<div style={{ display: "flex", flexDirection: "row", gap: "1em", width: '100%', justifyContent: 'start', alignItems: "start" }}>
                                                                    <div style={{ width: "3em" }}>
                                                                        {reply?.employee?.user?.profile?.profileImage ? <img style={{ height: '3em', width: "3em", borderRadius: "50%" }} src={reply?.employee?.user?.profile?.profileImage} alt="" /> :
                                                                            <div style={{ height: '3em', width: "3em !important", borderRadius: "50%", background: "#AABAC6", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                                                <h4 style={{ fontSize: "1.5em", textAlign: "center", fontWeight: "600", fontFamily: "Poppins", color: "#fff", margin: 0 }}>{Capitalize(reply?.employee?.user?.firstName?.charAt(0))}</h4>
                                                                            </div>}
                                                                    </div>

                                                                    <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
                                                                        <div style={{ display: "flex", flexDirection: "row", gap: ".5em", justifyContent: "start", alignItems: 'center' }}>
                                                                            <h4 className="kudos-card-user-name" style={{ margin: "0px" }}>{Capitalize(reply?.employee?.user?.firstName)} {Capitalize(reply?.employee?.user?.firstName)}</h4>
                                                                            <p className="kudos-card-user-name" style={{ color: 'rgba(108, 117, 125, 1)', fontSize: ".85em", fontWeight: "400" }}>{getRelativeTime(reply?.createdAt)}</p>
                                                                        </div>
                                                                        <h5 className="kudos-card-user-name" style={{ fontWeight: "400", fontSize: ".85em", margin: "0px" }}>{reply?.content}</h5>
                                                                        {permission && permission?.badgeawardcommentreaction && <div style={{ display: "flex", flexDirection: "row", gap: ".5em", justifyContent: "start", alignItems: "center" }}>
                                                                            {checkUserLiked(reply?.reactions?.edges) ? <img src={KudosIcons?.like_active} alt="like_active" style={{ cursor: "pointer" }} onClick={() => commnetReaction(reply?.id, 'like', reply?.reactions?.edges)} /> : <img src={KudosIcons?.kudos_heart_inactive} alt="like_active" style={{ cursor: "pointer" }} onClick={() => commnetReaction(reply?.id, 'like', reply?.reactions?.edges)} />}
                                                                            {reply?.reactions?.totalCount > 0 && <p className="kudos-card-user-name" style={{ margin: "0px", fontSize: ".75em" }}>{reply?.reactions?.totalCount}</p>}
                                                                        </div>}
                                                                    </div>
                                                                </div>))}

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Spin>
                                    </div>}
                                </div>
                            ))}
                            {!award_badge_loading &&
                                (
                                    issuer_page_info?.hasNextPage ||
                                    badge_page_info?.hasNextPagee ||
                                    receipient_page_info?.hasNextPage
                                ) ?
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                                    <h4 onClick={() => { onPagination({ issuer_cursor: issuer_page_info?.endCursor, badge_cursor: badge_page_info?.endCursor, receipient_cursor: receipient_page_info?.endCursor }) }} className='pagination-label' style={{ cursor: "pointer", margin: "0px", padding: "0px" }}>View More</h4>
                                </div> : ''}
                            {
                                (!data_source?.length && !award_badge_loading) ?
                                    <div className='rd-nodata' style={{ background: "transparent" }}>
                                        <h4 className='rd-nodata-label'>No Kudos Found</h4>
                                    </div> : ''
                            }
                        </div>
                    </Spin>
                </div>
                <div className="kudos-left-board-container" style={{ width: "30%", display: "flex", flexDirection: 'column', gap: "2em" }}>
                    <EngagementBoardContainer {...props} engagement_board_data={engagement_board_data} engagement_loading={engagement_loading} />
                    <YourKudosContainer {...props} />
                </div>
                <GiveKudosNewPopupContainer {...props} visible={give_kudos} onCancel={(e) => { setGiveKudos(e) }} />
                <KudosCustomPickerView selectedDateRange={(date) => onFilterCustomDate(date)} onClose={(e) => { onCloseDate() }} open_date_picker={open_date_picker} {...props} />
            </div>
        </div>


    )
}

export default KudosHomePageView;
const CustomSelect = styled(Select)`
.ant-select-selector{
    border: 1px solid #AABAC6 !impoortant;
    color:  #000;
    font-weight: 500;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius:.5em !important;
    width:13em !important;
    height: 40px !important;
    padding-top: 4px !important;
}
`;